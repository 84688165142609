<template>
  <div class="text-center my-20">
    <h1 class="title-font mb-4 text-3xl font-bold leading-8 tracking-tight sm:text-5xl sm:leading-4 md:text-6xl">
      {{ title }}
    </h1>
    <p class="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto mb-8">
      <slot></slot>
    </p>
    <div class="flex mt-6 justify-center">
      <div class="w-24 h-1 rounded-full inline-flex shadow" :class="lineClass"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SectionHeading",
  props: {
    title: {
      type: String,
      required: true,
    },
    lineClass: {
      type: String,
      default: 'bg-blue-400'
    }
  }
}
</script>

<style scoped>

</style>