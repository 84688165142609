<template>
  <div class="px-10 py-6 bg-white rounded-lg shadow-md">
    <div class="flex justify-between items-center">
      <span class="font-light text-gray-600">
        {{ date }}
      </span>
    </div>
    <div class="mt-2">
      <a href="#" class="text-2xl text-gray-700 font-bold hover:underline">
        {{title }}
      </a>
      <p class="mt-2 text-gray-600">
        {{ description }}
      </p>
    </div>
    <div class="flex justify-between items-center mt-4">
      <router-link tag="a" :to="{name: id + '-' + $i18n.locale}" class="text-blue-400 hover:underline">
        {{ $t('news.read_more') }}
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "NewsCard",
  props: [
      'id',
      'title',
      'date',
      'description'
  ],
}
</script>

<style scoped>

</style>