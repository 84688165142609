<template class="">
  <div class="bg-white rounded-md shadow p-4 ring-1 ring-gray-200 items-center flex-row transition delay-75 duration-300 select-none
  ease-in-out transform hover:-translate-y-2 p-6 hover:shadow-2xl  cursor-pointer">
    <div class="flex flex-none">
      <div class="flex-1 font-semibold">{{ title }}</div>
      <div class="flex-0">
        <slot></slot>
      </div>
    </div>
    <div class="mt-6 text-gray-500">
      <p>{{ desc }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ServiceCard",
  props: {
    title: {
      type: String,
      required: true
    },
    desc: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped>

</style>