<template>
  <div>
    <navigation-bar></navigation-bar>
    <main>
      <pictures></pictures>
      <news></news>
      <benefits></benefits>
      <service></service>
      <contact></contact>
    </main>
  </div>
</template>

<script>
import NavigationBar from "@/components/partials/NavigationBar";
import Pictures from "@/components/sections/Pictures";
import News from "@/components/sections/News";
import Benefits from "@/components/sections/Benefits";
import Service from "@/components/sections/Service";
import Contact from "@/components/sections/Contact";
export default {
  name: "Home",
  components: {Pictures, Contact, Service, Benefits, News, NavigationBar}
}
</script>

<style scoped>

</style>