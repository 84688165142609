<template>
  <div class="relative overflow-hidden min-h-screen pt-8 bg-gray-100">
    <div class="max-w-7xl mx-auto w-full bg-gray-100">
      <div class="relative z-10 pb-8  sm:pb-16 md:pb-20 w-full">

        <div class="w-full mb-10">
          <img class="h-40 w-auto mx-auto" src="./assets/logo.png" alt="">
        </div>

        <div class="w-full px-10 py-6 bg-white rounded-lg shadow-md">
          <div class="flex justify-between items-center">
      <span class="font-light text-gray-600">
        {{ newsData.date }}
      </span>
          </div>
          <div class="mt-2">
            <a href="#" class="text-2xl text-gray-700 font-bold hover:underline">
              {{ newsData["title_" + $i18n.locale] }}
            </a>
            <p class="mt-2 text-gray-600">
              <router-view></router-view>
            </p>
          </div>
          <div class="flex justify-between items-center mt-6">
            <a @click="goBack" class="text-blue-400 hover:underline cursor-pointer">&laquo; {{ $t('news.back') }}</a>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import NewsEntries from './static/news.json';
import i18n from "./i18n";

export default {
  name: "ShowNews",
  components: {},
  mounted() {
    this.getNewsData();
  },
  computed: {
    newsData() {
      return Object.keys(NewsEntries).flatMap(x => NewsEntries[x]).find(n => n.id + '-' + i18n.locale === this.$route.name);
    }
  },
  methods: {
    getNewsData() {
      return NewsEntries.map(n => n);
    },
    goBack() {
      if(this.$router.go(-1) === undefined) {
        this.$router.push({name: 'home'});
      }  else {
        return this.$router.go(-1);
      }
    }
  }
}
</script>