import './css/index.css'

import Vue from 'vue'
import router from './router/router';
import App from './App.vue'

//Lightbox
import VueLazyLoad from 'vue-lazyload'
Vue.use(VueLazyLoad)

Vue.config.productionTip = false

import i18n from './i18n'

let vue = new Vue({
  el: '#app',
  router,
  i18n,
  components: {
    App
  },
  template: '<App/>'
});

export { vue };
