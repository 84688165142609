<template>
  <div class="antialiased">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
  methods: {
    getUserLocales(options = {}) {
      const defaultOptions = {
        languageCodeOnly: false,
      };

      const opt = {
        ...defaultOptions,
        ...options,
      };

      const browserLocales =
          navigator.languages === undefined
              ? [navigator.language]
              : navigator.languages;

      if (!browserLocales) {
        return undefined;
      }

      return browserLocales.map(locale => {
        const trimmedLocale = locale.trim();

        return opt.languageCodeOnly
            ? trimmedLocale.split(/-|_/)[0]
            : trimmedLocale;
      });
    },
    getSupportedUserLocale(locales) {
      return locales.filter(l => this.getSupportedLocales().includes(l));
    },
    getSupportedLocales() {
      return ['fr', 'de'];
    }
  },
  mounted() {
    // Sets the locale to the first supported occurence of the browser locale.
    // if none found, set to french by default.
    this.$i18n.locale = this.getSupportedUserLocale(this.getUserLocales())[0] || 'fr';
  }
}
</script>

<style>

</style>
