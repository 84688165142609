<template>
  <section id="home" class="bg-white ">
    <div class="container mx-auto px-4 sm:px-6 lg:px-8 pt-2 pb-20">

      <section-heading :title="$t('pictures.catchphrase')">
        {{ $t('pictures.subtext') }}
      </section-heading>

      <div class="grid md:grid-cols-1 lg:grid-cols-2 gap-4 gap-y-16">
        <div class="" v-for="(image, index) in images" :key="index">
          <img :src="image.thumb" alt="" class="inset-0 w-full object-fill bg-gray-100 rounded-md cursor-pointer shadow-xl" @click="openGallery(index)"
          />
        </div>
      </div>
      <LightBox
          ref="lightbox"
          :media="images"
          :show-caption="true"
          :show-light-box="false"
      />
    </div>
  </section>
</template>

<script>
import SectionHeading from "@/components/partials/SectionHeading";
import LightBox from 'vue-image-lightbox'

export default {
  name: "Pictures",
  components: {SectionHeading, LightBox},
  data() {
    return {
      images: [
        {
          thumb: './img/login.JPG',
          src: './img/login.JPG',
        },
        {
          thumb: "./img/admin_panel.JPG",
          src: "./img/admin_panel.JPG",
        }, {
          thumb: './img/doctena.JPG',
          src: './img/doctena.JPG',
        }, {
          thumb: './img/soap.png',
          src: './img/soap.png',
        },
      ]
    }
  },
  methods: {
    openGallery(index) {
      this.$refs.lightbox.showImage(index)
    }
  }
}
</script>

<style scoped>

</style>